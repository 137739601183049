<template>
  <div>

    <HeaderPersonal />

    <div class="section color mid-line">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form two w-container">
        <div class="form-block w-form">
          <form id="email-form" name="email-form" data-name="Email Form" method="get" class="calkulate-box" data-wf-page-id="66682f534ebd3c1a5e4243fb" data-wf-element-id="4317ba24-ee03-e6a7-c0c6-d72d3391b1d2">
            <div class="tittle-form calcul">
              <a @click="goToFAQ" class="arrow-left calcul w-inline-block"><img src="../../../../../public/images/Union.svg" loading="lazy" alt="" class="arrow"></a>
              <h1 class="heading-form">Расчет платежей</h1>
            </div>
            <div class="calculators faq">
              <div class="flex-calculate faq">
                <div class="calculate-block one">
                  <div class="sum-top">
                    <div class="calculate-namber-box calcul">
                      <div class="text-mini">Сумма кредита</div>
                    </div>
                    <div class="sum-result right"><span class="sum">{{calculator_sum.toLocaleString()}}</span> <span class="rub">₽</span></div>
                  </div>
                  <div class="wrapper-block">
                    <div class="range-slaider w-embed">
                      <div class="calculator_bottom-slider slider-styled" id="slider-sum-calc"></div>
                    </div>
                  </div>
                </div>
                <div class="calculate-block">
                  <div class="sum-top">
                    <div class="calculate-namber-box calcul">
                      <div class="text-mini">Срок кредита в годах</div>
                    </div>
                    <div class="sum-result right"><span class="sum">{{calculator_period}}</span></div>
                  </div>
                  <div class="wrapper-block">
                    <div class="range-slaider w-embed">
                      <div class="calculator_bottom-slider slider-styled" id="slider-period-calc"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-calculate faq">
                <div class="calculate-block">
                  <div class="sum-top">
                    <div class="calculate-namber-box calcul">
                      <div class="text-mini">Процентная ставка</div>
                    </div>
                    <div class="sum-result right"><span class="day-namber">{{calculator_procent}}</span> %</div>
                  </div>
                  <div id="w-node-d6225ff3-9d1a-9ff5-cc89-083be7ff8589-5e4243fb" class="wrapper-block">
                    <div class="range-slaider w-embed">
                      <div class="calculator_bottom-slider" id="slider-procent-calc"></div>
                    </div>
                  </div>
                </div>
                <div class="input-box faq">
                  <label for="Data-4" class="input-label">Дата выдачи</label>
                  <v-text-field
                      id="Data-4"
                      name="Data-4"
                      outlined
                      type="date"
                      :rules="dateRules"
                      v-model="issue_date"
                  ></v-text-field>
                </div>
              </div>
              <div class="w-layout-hflex flex-item-best" style="padding-top: 0;">
                <a @click="calculation_schedule" class="button-bust w-button">Рассчитать график</a>
                <link rel="prerender">
              </div>
            </div>
          </form>
          <div v-if="dicts_payments.length > 0" class="w-layout-vflex grafic">
            <h1 class="heading-form">Ваш график платежей</h1>
            <div class="w-layout-vflex item-col">
              <div class="w-layout-hflex item-row">
                <div class="item-name one">
                  <div>№</div>
                </div>
                <div class="item-name">
                  <div>Дата</div>
                </div>
                <div class="item-name">
                  <div>Платеж</div>
                </div>
                <div class="item-name last">
                  <div>Остаток</div>
                </div>
              </div>
              <div v-for="(payment, index) in dicts_payments" :key="index" class="w-layout-hflex item-row number">
                <div class="item-number one">
                  <div class="number-one">{{index+1}}</div>
                </div>
                <div class="item-number two">
                  <div>{{payment.date}}</div>
                </div>
                <div class="item-number">
                  <div>{{payment.payment}} ₽</div>
                </div>
                <div class="item-number last">
                  <div>{{payment.remains}} ₽</div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderPersonal } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'Calculator',
  data() {
    return {
      dateRules: [
        v => !!v || 'Введите корректную дату',
        v => this.isValidDate(v) || 'Неправильный формат даты'
      ],

      issue_date: new Date().toISOString().substr(0, 10),

      calculator_period: 1,
      calculator_procent: 3,
      calculator_sum: 100000,

      // Опции для форматирования даты
      options: { day: 'numeric', month: 'long' },

      dicts_payments: [],
    };
  },
  components: {
    Footer,
    HeaderPersonal
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            this.jqueryLoaded();

            loadjs('./js/webflow.js', {
              success: () => {
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );
  },
  created() {
  },
  computed: {
  },
  methods: {
    calculation_schedule() {
      this.dicts_payments = [];

      let months = this.calculator_period * 12;

      // месячная процентная ставка
      let monthly_rate = this.calculator_procent/12/100;

      // коэффициент аннуитета
      let annuity_rate =
          monthly_rate * (1 + monthly_rate)**months /
          ((1 + monthly_rate)**months - 1);

      // ежемесячный аннуитетный платеж
      let monthly_payment = annuity_rate * this.calculator_sum;

      // сумма оставшегося долга
      var balance_of_debt = this.calculator_sum;

      // последняя дата
      var last_date = new Date(this.issue_date);

      for (let i = 1; i <= months; i++) {
        last_date.setMonth(last_date.getMonth() + 1);

        // сумма платежа в погашение процентов
        let sum_1 = balance_of_debt * monthly_rate;
        // сумма в погашение тела кредита
        let sum_2 = monthly_payment - sum_1;
        // сумма остатка долга
        balance_of_debt = balance_of_debt - sum_2;
        if (months === i) {
          balance_of_debt = 0;
        }

        this.dicts_payments.push({
          'date': this.formatDate(last_date),
          'payment': this.formatCurrency(monthly_payment),
          'remains': this.formatCurrency(balance_of_debt)
        });
      }

    },
    formatDate(date) {
      return date.toLocaleDateString('ru-RU', this.options);
    },
    formatCurrency(number) {
      // форматирует с пробелами между блоками чисел, а также округляет
      return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
    },
    jqueryLoaded() {
      // создание бегунков
      let slider = document.getElementById("slider-sum-calc"); // бегунок суммы
      let slider2 = document.getElementById("slider-period-calc"); // бегунок лет
      let slider3 = document.getElementById("slider-procent-calc"); // бегунок процентов

      noUiSlider.create(slider, {
        start: 100000, // значение бегунка при загрузке
        connect: "lower",
        range: {
          min: 50000, // минимальное значение бегунка суммы
          max: 30000000, // максимальное значение бегунка суммы
        },
        step:10000,
      });

      noUiSlider.create(slider2, {
        start: 5, // значение бегунка при загрузке
        connect: "lower",
        range: {
          min: 1, // минимальное значение бегунка дней
          max: 15, // максимальное значение бегунка дней
        },
      });

      noUiSlider.create(slider3, {
        start: 12, // значение бегунка при загрузке
        connect: "lower",
        range: {
          min: 3, // минимальное значение бегунка дней
          max: 30, // максимальное значение бегунка дней
        },
      });

      // ивенты бегунков
      slider.noUiSlider.on("slide", (values, handle) => {
        let dataValue = Math.round(values[handle]);
        this.calculator_sum = dataValue;
      });

      slider2.noUiSlider.on("slide", (values, handle) => {
        let dataValue = Math.round(values[handle]);
        this.calculator_period = dataValue;
      });

      slider3.noUiSlider.on("slide", (values, handle) => {
        let dataValue = Math.round(values[handle]);
        this.calculator_procent = dataValue;
      });

    },
    goToFAQ() {
      this.$router.push('/faq');
    },
    isValidDate(dateString) {
      // Проверка на пустую строку
      if (!dateString) return false;

      // Регулярное выражение для проверки формата YYYY-MM-DD
      let regEx = /^\d{4}-\d{2}-\d{2}$/;

      // Проверка соответствия формату
      if (!dateString.match(regEx)) return false;

      // Проверка, что дата валидна
      const d = new Date(dateString);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();

      // Проверка на правильное количество дней в месяце
      if (year < 1000 || year > 9999 || month < 1 || month > 12) return false;
      const daysInMonth = new Date(year, month, 0).getDate();
      if (day < 1 || day > daysInMonth) return false;

      return true;
    },
  }

};
</script>

<style scoped>
.noUi-horizontal {
  height: 30px !important;
}

.noUi-target,.noUi-target *{
  -webkit-touch-callout:none;
  -webkit-tap-highlight-color:transparent;
  -webkit-user-select:none;-ms-touch-action:none;touch-action:none;
  -ms-user-select:none;
  -moz-user-select:none;
  user-select:none;
  -moz-box-sizing:border-box;
  box-sizing:border-box}
.noUi-target{
  position:relative
}
.noUi-base,.noUi-connects{
  width:100%;
  height:100%;
  position:relative;
  z-index:1}
.noUi-connects{
  overflow:hidden;
  z-index:0
}
.noUi-connect,.noUi-origin{
  will-change:transform;
  position:absolute;
  z-index:1;
  top:0;
  right:0;
  height:100%;
  width:100%;
  -ms-transform-origin:0 0;
  -webkit-transform-origin:0 0;
  -webkit-transform-style:preserve-3d;
  transform-origin:0 0;transform-style:flat
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin{
  left:0;right:auto}
.noUi-vertical .noUi-origin{
  top:-100%;
  width:0
}
.noUi-horizontal .noUi-origin {
  height:0
}
.noUi-handle{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  position:absolute}
.noUi-touch-area{
  height:100%;
  width:100%
}
.noUi-state-tap .noUi-connect,.noUi-state-tap .noUi-origin{
  -webkit-transition:transform .3s;
  transition:transform .3s
}
.noUi-state-drag *{
  cursor:inherit!important
}
.noUi-horizontal{
  height:30px
}
.noUi-horizontal .noUi-handle{
  width:30px;
  height:30px;
  right:-15px;
  top:0px;
  /*
  background-image: url(https://uploads-ssl.webflow.com/652921c910bae02d8870dddf/66604534bd0b08e7df20b6c6_Wundle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
   */
}
.noUi-vertical{
  width:46px
}
.noUi-vertical .noUi-handle{
  width:30px;
  height:30px;
  right:-15px;
  bottom:0px;
  /*
  background-image: url(https://uploads-ssl.webflow.com/652921c910bae02d8870dddf/66604534bd0b08e7df20b6c6_Wundle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  */
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle{
  left:-30px;
  right:auto
}
.noUi-target{
  background:#E7E6E9;
  border-radius:50px;
}
.noUi-connects{
  border-radius: 50px 0px 0px 50px;
}
.noUi-connect{
  background: linear-gradient(180deg, #66B72C 0%, #5F9E32 100%), #362D4B;
}
.noUi-draggable{
  cursor:ew-resize
}
.noUi-vertical .noUi-draggable{
  cursor:ns-resize
}
.noUi-handle{
  border:1px solid #fff;
  border-radius:20px;
  background:#FFF;
  cursor:default;
}
/* .noUi-active{
 box-shadow:inset 0 0 1px #FFF,inset 0 1px 7px #DDD,0 3px 6px -3px #BBB
 } */
.noUi-vertical .noUi-handle:after,.noUi-vertical .noUi-handle:before{
  width:14px;
  height:1px;
  left:6px;
  top:14px
}
.noUi-vertical .noUi-handle:after{
  top:20px
}
[disabled] .noUi-connect{
  background:#B8B8B8
}
[disabled] .noUi-handle,[disabled].noUi-handle,[disabled].noUi-target{
  cursor:not-allowed
}
.noUi-pips,.noUi-pips *{
  -moz-box-sizing:border-box;
  box-sizing:border-box
}
.noUi-pips{
  position:absolute;
  color:#999
}
.noUi-value{
  position:absolute;
  white-space:nowrap;
  text-align:center
}
.noUi-value-sub{
  color:#ccc;
  font-size:10px
}
.noUi-marker{
  position:absolute;
  background:#CCC
}
.noUi-marker-sub{
  background:#AAA
}
.noUi-marker-large{
  background:#AAA
}
.noUi-pips-horizontal{
  padding:10px 0;
  height:80px;
  top:100%;
  left:0;
  width:100%
}
.noUi-value-horizontal{
  -webkit-transform:translate(-50%,50%);
  transform:translate(-50%,50%)
}
.noUi-rtl .noUi-value-horizontal{
  -webkit-transform:translate(50%,50%);
  transform:translate(50%,50%)
}
.noUi-marker-horizontal.noUi-marker{
  margin-left:-1px;
  width:2px;
  height:5px
}
.noUi-marker-horizontal.noUi-marker-sub{
  height:10px
}
.noUi-marker-horizontal.noUi-marker-large{
  height:15px
}
.noUi-pips-vertical{
  padding:0 10px;
  height:100%;
  top:0;
  left:100%
}
.noUi-value-vertical{
  -webkit-transform:translate(0,-50%);
  transform:translate(0,-50%);
  padding-left:25px
}
.noUi-rtl .noUi-value-vertical{
  -webkit-transform:translate(0,50%);
  transform:translate(0,50%)
}
.noUi-marker-vertical.noUi-marker{
  width:5px;
  height:2px;
  margin-top:-1px
}
.noUi-marker-vertical.noUi-marker-sub{
  width:10px
}
.noUi-marker-vertical.noUi-marker-large{
  width:15px
}
.noUi-tooltip{
  display:block;
  position:absolute;
  border:1px solid #D9D9D9;
  border-radius:3px;
  background:#fff;
  color:#000;
  padding:5px;
  text-align:center;
  white-space:nowrap
}
.noUi-horizontal .noUi-tooltip{
  -webkit-transform:translate(-50%,0);
  transform:translate(-50%,0);
  left:50%;
  bottom:120%
}
.noUi-vertical .noUi-tooltip{
  -webkit-transform:translate(0,-50%);
  transform:translate(0,-50%);
  top:50%;
  right:120%
}
.noUi-horizontal .noUi-origin>.noUi-tooltip{
  -webkit-transform:translate(50%,0);
  transform:translate(50%,0);
  left:auto;
  bottom:10px
}
.noUi-vertical .noUi-origin>.noUi-tooltip{
  -webkit-transform:translate(0,-18px);
  transform:translate(0,-18px);
  top:auto;
  right:28px
}


/* СКРИПТ НОМЕР ДВА */
.noUi-target,.noUi-target *{
  -webkit-touch-callout:none;
  -webkit-tap-highlight-color:transparent;
  -webkit-user-select:none;-ms-touch-action:none;touch-action:none;
  -ms-user-select:none;
  -moz-user-select:none;
  user-select:none;
  -moz-box-sizing:border-box;
  box-sizing:border-box}
.noUi-target{
  position:relative
}
.noUi-base,.noUi-connects{
  width:100%;
  height:100%;
  position:relative;
  z-index:1}
.noUi-connects{
  overflow:hidden;
  z-index:0
}
.noUi-connect,.noUi-origin{
  will-change:transform;
  position:absolute;
  z-index:1;
  top:0;
  right:0;
  height:100%;
  width:100%;
  -ms-transform-origin:0 0;
  -webkit-transform-origin:0 0;
  -webkit-transform-style:preserve-3d;
  transform-origin:0 0;transform-style:flat
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin{
  left:0;right:auto}
.noUi-vertical .noUi-origin{
  top:-100%;
  width:0
}
.noUi-horizontal .noUi-origin {
  height:0
}
.noUi-handle{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  position:absolute}
.noUi-touch-area{
  height:100%;
  width:100%
}
.noUi-state-tap .noUi-connect,.noUi-state-tap .noUi-origin{
  -webkit-transition:transform .3s;
  transition:transform .3s
}
.noUi-state-drag *{
  cursor:inherit!important
}
.noUi-horizontal{
  height:30px
}
.noUi-horizontal .noUi-handle{
  width:30px;
  height:30px;
  right:-15px;
  top:0px;
  /*
  background-image: url(https://uploads-ssl.webflow.com/652921c910bae02d8870dddf/66604534bd0b08e7df20b6c6_Wundle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;*/
}
.noUi-vertical{
  width:46px
}
.noUi-vertical .noUi-handle{
  width:30px;
  height:30px;
  right:-15px;
  bottom:0px;
  /*
  background-image: url(https://uploads-ssl.webflow.com/652921c910bae02d8870dddf/66604534bd0b08e7df20b6c6_Wundle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;*/
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle{
  left:-30px;
  right:auto
}
.noUi-target{
  background:#E7E6E9;
  border-radius:50px;
}
.noUi-connects{
  border-radius: 50px 0px 0px 50px;
}
.noUi-connect{
  background: linear-gradient(180deg, #66B72C 0%, #5F9E32 100%), #362D4B;
}
.noUi-draggable{
  cursor:ew-resize
}
.noUi-vertical .noUi-draggable{
  cursor:ns-resize
}
.noUi-handle{
  border:1px solid #fff;
  border-radius:20px;
  background:#FFF;
  cursor:default;
}
/* .noUi-active{
 box-shadow:inset 0 0 1px #FFF,inset 0 1px 7px #DDD,0 3px 6px -3px #BBB
 } */
.noUi-vertical .noUi-handle:after,.noUi-vertical .noUi-handle:before{
  width:14px;
  height:1px;
  left:6px;
  top:14px
}
.noUi-vertical .noUi-handle:after{
  top:20px
}
[disabled] .noUi-connect{
  background:#B8B8B8
}
[disabled] .noUi-handle,[disabled].noUi-handle,[disabled].noUi-target{
  cursor:not-allowed
}
.noUi-pips,.noUi-pips *{
  -moz-box-sizing:border-box;
  box-sizing:border-box
}
.noUi-pips{
  position:absolute;
  color:#999
}
.noUi-value{
  position:absolute;
  white-space:nowrap;
  text-align:center
}
.noUi-value-sub{
  color:#ccc;
  font-size:10px
}
.noUi-marker{
  position:absolute;
  background:#CCC
}
.noUi-marker-sub{
  background:#AAA
}
.noUi-marker-large{
  background:#AAA
}
.noUi-pips-horizontal{
  padding:10px 0;
  height:80px;
  top:100%;
  left:0;
  width:100%
}
.noUi-value-horizontal{
  -webkit-transform:translate(-50%,50%);
  transform:translate(-50%,50%)
}
.noUi-rtl .noUi-value-horizontal{
  -webkit-transform:translate(50%,50%);
  transform:translate(50%,50%)
}
.noUi-marker-horizontal.noUi-marker{
  margin-left:-1px;
  width:2px;
  height:5px
}
.noUi-marker-horizontal.noUi-marker-sub{
  height:10px
}
.noUi-marker-horizontal.noUi-marker-large{
  height:15px
}
.noUi-pips-vertical{
  padding:0 10px;
  height:100%;
  top:0;
  left:100%
}
.noUi-value-vertical{
  -webkit-transform:translate(0,-50%);
  transform:translate(0,-50%);
  padding-left:25px
}
.noUi-rtl .noUi-value-vertical{
  -webkit-transform:translate(0,50%);
  transform:translate(0,50%)
}
.noUi-marker-vertical.noUi-marker{
  width:5px;
  height:2px;
  margin-top:-1px
}
.noUi-marker-vertical.noUi-marker-sub{
  width:10px
}
.noUi-marker-vertical.noUi-marker-large{
  width:15px
}
.noUi-tooltip{
  display:block;
  position:absolute;
  border:1px solid #D9D9D9;
  border-radius:3px;
  background:#fff;
  color:#000;
  padding:5px;
  text-align:center;
  white-space:nowrap
}
.noUi-horizontal .noUi-tooltip{
  -webkit-transform:translate(-50%,0);
  transform:translate(-50%,0);
  left:50%;
  bottom:120%
}
.noUi-vertical .noUi-tooltip{
  -webkit-transform:translate(0,-50%);
  transform:translate(0,-50%);
  top:50%;
  right:120%
}
.noUi-horizontal .noUi-origin>.noUi-tooltip{
  -webkit-transform:translate(50%,0);
  transform:translate(50%,0);
  left:auto;
  bottom:10px
}
.noUi-vertical .noUi-origin>.noUi-tooltip{
  -webkit-transform:translate(0,-18px);
  transform:translate(0,-18px);
  top:auto;
  right:28px
}
</style>
